import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Routes, RouterModule} from '@angular/router';
import {MatCardModule} from '@angular/material/card';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ForgotPasswordResetComponent} from './forgot-password/forgot-password-reset/forgot-password-reset.component';
import {AuthComponent} from './auth.component';
import {LibAuthAng13Module} from '@symbiotics/lib-auth-ang14';
import {HttpClientModule} from '@angular/common/http';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { PasswordResetRequiredComponent } from './password-reset-required/password-reset-required.component';
import { MaterialModule } from '../material/material.module';


const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'confirm-email/:users',
    component: ConfirmEmailComponent,
  },
  {
    path: 'forgot-password-reset/:user',
    component: ForgotPasswordResetComponent,
  },
  {
    path: 'password-reset-required/:user',
    component: PasswordResetRequiredComponent
  },
];
@NgModule({
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    ForgotPasswordResetComponent,
    PasswordResetRequiredComponent,
    AuthComponent, RegisterComponent,
    ConfirmEmailComponent],
  imports: [
    CommonModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    LibAuthAng13Module,
    RouterModule.forChild(routes),
    HttpClientModule,
    FlexModule,
    FlexLayoutModule
  ]
})
export class AuthModule {
}
