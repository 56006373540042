<div style="padding: 12px;" class="displayLibsCenter">
    <mat-card fxFlex="30" fxLayout="column"
        style="width: 30%;height: 50%;left: 35%;right: 35%">
        <lib-login style="width: 100%; max-width: 100%;" formFieldOutline="outline" [displayTopHeader]="false"
            [passwordResetRequiredLink]="'login/password-reset-required'" [registerUserLink]="true"
            [registerRoute]="'login/register'" [successLink]="''" [forgotPasswordLink]='true'
            [forgotPasswordRoute]="'login/forgot-password'" [apiEndpoint]="endPoint" [logoImage]="imageSrc"  [userNameEmailLabel]="'Email'"></lib-login>
    </mat-card>

        

</div>
<!-- (errorState)="displayErrorMessage($event)" -->
