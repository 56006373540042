import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private httpClient: HttpClient) { }

  private REST_API_SERVER = '';
  errorMessage = '';
  /** Contains URL for redirect */
  redirectUrl: any;

  handleError(error) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.error.error}`;
    }
    return throwError(errorMessage);
  }

  sendEmailVerifyUserRequest(body) {
    return this.httpClient.put<any>(this.REST_API_SERVER + '/user/confirm', {
      username: body.username,
      code: body.code,
    }, { observe: 'response' }).pipe(retry(3), catchError(this.handleError));
  }
}
