
const REGION_PRIMARY = 'eu-west-1';

const BUCKET_PRIMARY = 'dev-deployments-tlvt-co-za';
const FILES_BUCKET = 'za-co-tlvt-clientdocuments'; 
const IDENTITY_POOL = 'eu-west-1:1b46cbc1-5471-49e1-90cd-c61840ae90d0';
const USER_POOL_ID = 'eu-west-1_nKiMWUObi';
const CLIENT_ID = 'p7ut0hnjinsrv29ga7gb811mb';

const DOCUMENT_GENERATOR = " https://api.dev.tlvt.co.za/clientdocument/v1/documentgenerator/cor05";
const USER_MANAGEMENT_BASE_PATH = 'https://api.dev.tlvt.co.za/clientusermanagement/v1';
const APPLICATION_BASE_PATH = 'https://api.dev.tlvt.co.za/application/v1';
const CLIENT_MANAGEMENT_BASE_PATH = 'https://api.dev.tlvt.co.za/clientmanagement/v1/';
const DIGITAL_SIGNATURE_BASE_PATH = 'https://api.dev.tlvt.co.za/clientdocument/v1/documentservices/digitalsignature';
const DOCUMENT_BASE_PATH = ' https://api.dev.tlvt.co.za/clientdocument/v1/documentservices';
const COMMENT_BASE_PATH = 'https://api.dev.tlvt.co.za/commentservice/v1/commentservice';
const LOOKUP_MANAGEMENT_BASE_PATH = 'https://api.dev.tlvt.co.za/lookupservice/v1/lookupservice/';
const COR_04_BASE_PATH = 'https://api.dev.tlvt.co.za/cor04management/v1/cor04service/byapplicationid/';

export const environment = {
  production: false,
  
  bucketName: BUCKET_PRIMARY,
  filesBucket: '',
  bucketRegion: REGION_PRIMARY,

  Storage: {
    bucket: BUCKET_PRIMARY,
    region: REGION_PRIMARY,
  },

  API: {
    userManagement: USER_MANAGEMENT_BASE_PATH,
    applicationManagement: APPLICATION_BASE_PATH,
    clientManagement: CLIENT_MANAGEMENT_BASE_PATH,
    signatureManagement: DIGITAL_SIGNATURE_BASE_PATH,
    documentManagement: DOCUMENT_BASE_PATH,
    commentManagement: COMMENT_BASE_PATH,
    lookupManagement: LOOKUP_MANAGEMENT_BASE_PATH,
    cor04: COR_04_BASE_PATH,
    generateCOR05: DOCUMENT_GENERATOR
  },
};
