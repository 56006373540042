import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanActivateChild,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";
import { JwtHelperService } from "@auth0/angular-jwt";

/**
 * Authguard component for checking route authentication
 */
@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate, CanActivateChild {
  jwtHelperService = new JwtHelperService();
  expTime: any;
  isLoggedIn = false;
  exp: any;
  d1 = new Date();
  /**
   * The constructor
   * @param router Router service for navigation
   * @param authService Authentication service for http requests
   */
  constructor(
    private readonly router: Router,
    private readonly authService: AuthService
  ) {}

  /**
   * Determines whether the next route can be activated
   * @param next The next route in the queue
   * @param state The current state of the router
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let url: string = state.url;

    return this.checkLogin(url);
  }

  /**
   * Determines whether a child route can be activated
   * @param route The child route
   * @param state The current state of the router
   */
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.canActivate(route, state);
  }

  /**
   * Determines logged in state of user
   * @param url The next url in the queue
   */
  checkLogin(url: string): boolean {
    let currentUser;
    if (JSON.parse(localStorage.getItem("currentUser"))) {
      currentUser = JSON.parse(localStorage.getItem("currentUser"));
    } else {
      currentUser = null;
    }
    if (sessionStorage.getItem("loggedIn") !== null) {
      if (currentUser !== null) {
        const isLogged = currentUser.isLoggedIn;
        if (isLogged) {
          return true;
        }
      } else {
        this.goToLogin(url);
      }
    } else {
      this.goToLogin(url);
    }
  }

  goToLogin(url: string): boolean {
    this.authService.redirectUrl = url;
    this.router.navigate(["/login"]);
    this.clearLocalStorage();
    sessionStorage.clear();
    return false;
  }

  clearLocalStorage() {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("all-applications");
    localStorage.removeItem("newApplicationLookup");
    localStorage.removeItem("randid");
    localStorage.removeItem("industry");
    localStorage.removeItem("error");
    localStorage.removeItem("totalComments");
    localStorage.removeItem("marketing");
    localStorage.removeItem("organisationalDetails");
    localStorage.removeItem("services");
  }
}
