import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
declare module '@angular/core' {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
    providers?: Provider[];
  }
}
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {HttpClientModule} from '@angular/common/http';
import {LayoutModule} from './layout/layout.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppComponent} from './app.component';
import {BaseModule} from './core/base/base.module';
import {MaterialModule} from './core/material/material.module';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatDividerModule} from '@angular/material/divider';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatStepperModule} from '@angular/material/stepper';
import {MatMenuModule} from '@angular/material/menu';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {AuthModule} from './core/auth/auth.module';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {HttpInterceptorService} from './core/services/http-interceptor-service';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {Ng2TelInputModule} from 'ng2-tel-input';
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BaseModule,
    MaterialModule,
    AppRoutingModule,
    LayoutModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatDividerModule,
    MatDatepickerModule,
    MatStepperModule,
    MatMenuModule,
    MatSnackBarModule,
    HttpClientModule,
    AuthModule,
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: HttpInterceptorService,
    multi: true
  },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-GB'
    }],
  bootstrap: [AppComponent]
})
export class AppModule {
}
