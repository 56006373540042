import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-warning',
  templateUrl: './user-warning.component.html',
  styleUrls: ['./user-warning.component.scss']
})
export class UserWarningComponent implements OnInit {

  currentUser: any;
  firstLogin: boolean;
  constructor(private router: Router, @Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<UserWarningComponent>) { }

  ngOnInit(): void {
    console.log(this.data);
    this.getUserDetails()
  }
  gotoProfile() {
    this.router.navigateByUrl('users-settings');
    this.closeDialog();
  }
  closeDialog() {
    this.dialogRef.close();
  }

  getUserDetails() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser) {
      this.firstLogin = this.currentUser.firstLogin;
    }
  }

}
