import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class LookupService {
  newApplicationLookupData: any;
  lookupApi = environment.API.lookupManagement;
  constructor(private http: HttpClient) {
    // this.getNewApplicationLookUp();
    // this.getApplicationLookUp();
  }

  getApplicationLookUp() {
    return this.http.get(this.lookupApi + 'lookup');
  }

  getNewApplicationLookUp() {
    const lookUpMock = {
      industrialSector: [
        'ICT',
        'Forestry',
        'Fishing',
        'Mining',
        'Manufacturing',
        'Construction',
        'Retail',
        'Tourism',
        'Hospitality',
        'Transport',
        'Finance',
        'Law',
        'Agriculture',
        'Defence',
        'Media',


      ],
      serviceRequired: [
        'Initial BEE Verification',
        'Annual BEE Verification',
        'B-BBEE Consulting',
        'BEE Scenario Planning',
        'Scorecard Compilation',
        'Document Preparation',
        'Opinion',
      ],
      marketing: [
        'Referal from TLVT Client',
        'SANAS website',
        'TLVT website',
        'Google',
        'Consultant',
      ],
      orginatingOffice: [
        'Cape Town',
        'Johannesburg'
      ],
      conflictOfInterest: [
        'Shareholding',
        'Personnel',

      ],
      consultantList: [
        'Lanham-Love Consulting',
        'Dorrington Matthee Consulting cc',
        'Gestalt Growth Strategies (Pty) Ltd'
      ],
      provinceList: [
        'Eastern Cape',
        'Free State',
        'Gauteng',
        'KwaZulu-Natal',
        'Western Cape',
        'Limpopo',
        'Mpumalanga',
        'Northern Cape',
        'North West',
      ],
      accountType: [
        'Audited Accounts',
        'Managed Accounts',
      ]
    };
    localStorage.setItem('newApplicationLookup', JSON.stringify(lookUpMock));
    this.newApplicationLookupData = lookUpMock;
  }
}
