import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersSettingsService } from 'src/app/features/users-settings/users-settings.service';
import { MatDialog } from '@angular/material/dialog';
import { UserWarningService } from 'src/app/shared/components/user-warning/user-warning.service';
import { LoadingService } from 'src/app/shared/components/loading/loading.service';
import { LookupService } from 'src/app/core/services/lookup/lookup.service';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  id: any;
  currentUser: any;
  userInfo: any;
  servicesList: any;
  servicesValuesList: any;
  marketingStatusList: any;
  marketingStatusValueList: any;
  industryStatusList: any;
  industryStatusValueList: any;
  sharedInterestList: any;
  sharedInterestValueList: any;
  userId: any;
  companyName: any;
  hasDigitalSignature: boolean = false;

  constructor(private router: Router, public userService: UsersSettingsService, public loadingservice: LoadingService, public lookupService: LookupService, public dialog: MatDialog, private userWarningService: UserWarningService

  ) { }

  ngOnInit(): void {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    this.companyName = user.companyName;
    this.userId = user.userId;
    console.log(user);
    this.welcomeUser();
    const localSignature = localStorage.getItem('hasDigitalSignature');

    if(!localSignature) {
      this.getDigitalSignature();
    } else {
      this.hasDigitalSignature = localSignature === 'true' ? true : false;
    }
  }

  getDigitalSignature() {
    const companyName = this.companyName;
    const identifierType = "ClientSignature";
    const identifierId = this.userId;
    this.loadingservice.openBlockingLoader('Loading, please wait','primary', 'indeterminate');
    
    this.userService.getDigitalSignature(companyName, identifierType, identifierId).subscribe((res: any) => {
          console.log(res);
          if(res.document && res.document.enabled) {
            this.hasDigitalSignature = true;
          } else {
            this.hasDigitalSignature = false;
          }
          localStorage.setItem('hasDigitalSignature', JSON.stringify(this.hasDigitalSignature));
          this.loadingservice.closeBlockingLoader();
        },
        (error) => {
          this.hasDigitalSignature = false;
          localStorage.setItem('hasDigitalSignature', JSON.stringify(this.hasDigitalSignature));
          this.loadingservice.closeBlockingLoader();
        });
  }

  getLookupData() {
    this.loadingservice.openBlockingLoader('Loading', 'primary');
    this.userService.getUser(this.userId).subscribe((res: any) => {
      localStorage.setItem('fullName', JSON.stringify(res.firstname + ' ' + res.lastname));
    });
    this.lookupService.getApplicationLookUp().subscribe(
      (res: any) => {
        const response = res.lookupList;
        for (let i = 0; i < response.length; i++) {
          let code = response[i].code;
          let values = response[i];
          if (code && code !== undefined) {
            if (code.includes("marketing") || code.includes("Marketing")) {
              this.marketingStatusList = values;
              this.setMarketingStatusValuesList();
              localStorage.setItem("marketing",JSON.stringify(this.marketingStatusValueList));
            } else if (code.includes("services") ||code.includes("Services" || code.includes("Services Required"))) {
              this.servicesList = values;
              this.setServicesValuesList();
              localStorage.setItem("services",JSON.stringify(this.servicesValuesList));
            } else if (code.includes("Industry") || code.includes("industry")) {
              this.industryStatusList = values;
              this.setIndustryValuesList();
              localStorage.setItem("industry",JSON.stringify(this.industryStatusValueList));
            }else if (code.includes("Shared") || code.includes("shared interest")) {
              this.sharedInterestList = values;
              this.setSharedInterestValueList();
              localStorage.setItem("sharedInterest",JSON.stringify(this.sharedInterestValueList));
            }
          }
        }
        localStorage.setItem('error',JSON.parse('false'));
      },
      (error) => {
        this.loadingservice.closeBlockingLoader();
      }
    );
    this.loadingservice.closeBlockingLoader();
  }

  setMarketingStatusValuesList() {
    if (this.marketingStatusList.values.length > 0) {
      let counter = 0;
      for (let i = 0; i < this.marketingStatusList.values.length; i++) {
        counter++;
        if (counter === 1) {
          this.marketingStatusValueList = this.marketingStatusList.values;
        }
      }
    } else {
      this.marketingStatusValueList = [];
    }
  }

  setSharedInterestValueList() {
    if(this.sharedInterestList.values.length > 0) {
      let counter = 0;
      for(let i = 0; i < this.sharedInterestList.values.length; i++) {
        counter++;
        if(counter ===1) {
          this.sharedInterestValueList = this.sharedInterestList.values;
        }
      }
    }else {
      this.sharedInterestValueList = [];
    }
  }

  setServicesValuesList() {
    if (this.servicesList.values.length > 0) {
      let counter = 0;
      for (let i = 0; i < this.servicesList.values.length; i++) {
        counter++;
        if (counter === 1) {
          this.servicesValuesList = this.servicesList.values;
        }
      }
    } else {
      this.servicesValuesList = [];
    }
  }

  setIndustryValuesList() {
    if (this.industryStatusList.values.length > 0) {
      let counter = 0;
      for (let i = 0; i < this.industryStatusList.values.length; i++) {
        counter++;
        if (counter === 1) {
          this.industryStatusValueList = this.industryStatusList.values;
        }
      }
    } else {
      this.servicesValuesList = [];
    }
  }

  myApplications() {
    this.router.navigateByUrl('/applications');
  }

  newApplication() {
    if(this.hasDigitalSignature) {
      console.log(JSON.parse(localStorage.getItem('companyDetailsUpdated')));
      if(!JSON.parse(localStorage.getItem('organisationalDetails')) ||  JSON.parse(localStorage.getItem('companyDetailsUpdated'))){
        console.log('fart');
        this.getCompanyDetails();
      }else {
        this.router.navigateByUrl('new-application');
      }
    } else {
      this.userWarningService.openUserWarning(false, this.companyName ? true : false);
    }
    
  }

  getCompanyDetails() {
    this.loadingservice.openBlockingLoader('Loading, please wait','primary', 'indeterminate');
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const companyName = user.companyName;
    this.userService.getCompanyDetails(companyName).subscribe(
      (res: any) => {
        localStorage.setItem('companyDetailsUpdated', JSON.stringify(false))
        console.log(res.clientModel);
        localStorage.setItem("organisationalDetails",JSON.stringify(res.clientModel));
        this.loadingservice.closeBlockingLoader();
        this.router.navigateByUrl('new-application');
      },
      (error) => {
        this.loadingservice.closeBlockingLoader();
        this.router.navigateByUrl('new-application');
      }
    );
  }

  welcomeUser() {
    this.getLookupData();
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser) {
      this.userInfo = this.currentUser.firstname + ' ' + this.currentUser.lastname;
    }
    
    // if(JSON.parse(localStorage.getItem('error'))) {
    //   console.log('trueeeee');
    //   this.getLookupData();
    // }
    // if(!JSON.parse(localStorage.getItem('industry')) || !JSON.parse(localStorage.getItem('marketing')) || !JSON.parse(localStorage.getItem('services'))) {
    //   this.getLookupData();
    // }
  }
}
