import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'sym-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss']
})
export class ConfirmEmailComponent implements OnInit {
  endPoint = environment.API.userManagement;
  imageSrc = '../assets/logos/tlvt-logo.png';

  constructor() { }

  ngOnInit(): void {
  }

}
