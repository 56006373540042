import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { UsersSettingsService } from 'src/app/features/users-settings/users-settings.service';

@Component({
  selector: 'sym-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit{

  currentUser = JSON.parse(localStorage.getItem('currentUser'));
  endPoint = environment.API.userManagement;
  imageSrc = '../assets/logos/tlvt-logo.png';
  user: any;
  userName: any;
  userId: any;
  constructor() {

  }

  
  ngOnInit(): void {
    this.clearLocalStorage();
    sessionStorage.clear();
    sessionStorage.setItem('loggedIn','yes');
  }

  displayErrorState(status: string) {
    console.log(status + '  ERROR');
  }
  displayErrorMessage(status: string) {
    console.log(status + '  ERROR');
  }
  clearLocalStorage() {
    localStorage.clear();
    // localStorage.removeItem("currentUser");
    // localStorage.removeItem("all-applications");
    // localStorage.removeItem("newApplicationLookup");
    // localStorage.removeItem("randid");
    // localStorage.removeItem("industry");
    // localStorage.removeItem("error");
    // localStorage.removeItem("totalComments");
    // localStorage.removeItem("marketing");
    // localStorage.removeItem("organisationalDetails");
    // localStorage.removeItem("services");
  }
}
