<sym-info-card cdkDrag fxFlex id="addCard" [title]="'Please Note'" showButton="true" icon="clear"
    (buttonClicked)=" closeDialog()">
    <div class="login-container" fxFlexFill fxLayoutAlign="center center">
        <div fxFlexFill class="input-container" fxLayoutAlign="center center" fxLayout="column" fxFlex>
            <div style="font-size: medium;">Additional information is needed in your profile section,</div>
            <div style="font-size: medium;margin-bottom: 10px;">before a new application can be created</div>
            <div *ngIf="!data.hasCompanyName" style="font-size: medium;color: red;">*Please add your company details</div>
            <div *ngIf="!data.hasDigitalSignature" style="font-size: medium;margin-bottom: 10px;color: red;">*Create your digital signature</div>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center">
        <button mat-raised-button style="width: 16%; margin: 5px;padding: 5px;background-color:  #0D2430;color: white;"
            id="btnClass" matTooltip="Go To Profile" matTooltipPosition="before" (click)="gotoProfile()">
            <mat-icon style="color: white;margin-right: 3px;">account_circle</mat-icon>Profile
        </button>
    </div>
</sym-info-card>
