import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UsersSettingsService {
    item: any;
    public userList: any;
    usersApi = environment.API.userManagement;
    companyApi = environment.API.clientManagement;
    signatureApi = environment.API.signatureManagement;
    documentApi = environment.API.documentManagement;

    constructor(private http: HttpClient) {
    }

    getUser(id) {
        return this.http.get(this.usersApi + '/' + id);
    }
    
    updateUser(user,id) {
        return this.http.put(this.usersApi + '/' + id,user);
    }

    getCompanyDetails(id){
        return this.http.get(this.companyApi + 'clientservice/companyName/' + id);
    }

    createCompany(company){
        return this.http.post(this.companyApi + 'clientservice/all',company);
    }

    updateCompanyDetails(id,company){
        return this.http.put(this.companyApi + 'clientservice/clientid/' + id,company);
    }

    getDigitalSignature(client, identifierType, identifierId){
        return this.http.get(this.signatureApi +'?client=' + client + '&identifierType='+ identifierType + '&identifierId=' + identifierId);
    }

    uploadDigitialSignature(body){
        return this.http.post(this.signatureApi,body);
    }
    
    uploadToS3(url,signature) {
        return this.http.put(url,signature);
    }

    deleteAllDigitalSignatures(id) {
        return this.http.delete(this.documentApi + '/delete/' + id);
    }

    getFromS3(url) {
        return this.http.get(url, {responseType: 'blob'});
    }

    refreshToken(refreshTokens) {
        this.item = {
            refreshToken: refreshTokens,
        }
        return this.http.post(this.usersApi + '/auth/refreshtoken', this.item);
    }
}
