import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationService } from '../../../application/application.service';
import { NewApplicationService } from '../../../application/new-application/new-application.service';
import { LookupService } from '../../../core/services/lookup/lookup.service';
import { UsersSettingsService } from 'src/app/features/users-settings/users-settings.service';
import { ComsService } from '../coms/coms.service';
import { LoadingService } from '../loading/loading.service';

@Component({
  selector: 'sym-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  loggedOffUser: any;
  userFullName: any;
  currentUser: any;
  user: any;
  userName: any;
  userId: any;
  count = 0;
  servicesList: any;
  servicesValuesList: any;
  marketingStatusList: any;
  marketingStatusValueList: any;
  industryStatusList: any;
  industryStatusValueList: any;
  totalComments: any;

  constructor(
    private router: Router,
    public loadingservice: LoadingService,
    public coms: ComsService,
    public newApplicationService: NewApplicationService,
    public loadingService: LoadingService,
    public userService: UsersSettingsService,
    private applicationsService: ApplicationService,
    public lookupService: LookupService
  ) { }

  ngOnInit() {
    if(JSON.parse(localStorage.getItem('totalComments'))){
      this.totalComments = JSON.parse(localStorage.getItem('totalComments'));
    }
    if(!JSON.parse(localStorage.getItem('marketing')) || !JSON.parse(localStorage.getItem('services')) || !JSON.parse(localStorage.getItem('industry'))) {
        this.getUserProfile();
        this.getLookupData();
    }
    this.getUserDetails();
    this.applicationsService.getNotifications.subscribe((data: any) => {
      if(data){
        this.getAmmountOfErrors();
      }
     })
  }

  getLookupData() {
    this.lookupService.getApplicationLookUp().subscribe(
      (res: any) => {
        const response = res.lookupList;
        for (let i = 0; i < response.length; i++) {
          let code = response[i].code;
          let values = response[i];
          if (code && code !== undefined) {
            if (code.includes("marketing") || code.includes("Marketing")) {
              this.marketingStatusList = values;
              this.setMarketingStatusValuesList();
              localStorage.setItem("marketing",JSON.stringify(this.marketingStatusValueList));
            } else if (code.includes("services") || code.includes("Services" || code.includes("Services Required")) ) {
              this.servicesList = values;
              this.setServicesValuesList();
              localStorage.setItem( "services",JSON.stringify(this.servicesValuesList));
            } else if (code.includes("Industry") || code.includes("industry")) {
              this.industryStatusList = values;
              this.setIndustryValuesList();
              localStorage.setItem( "industry",JSON.stringify(this.industryStatusValueList));
            }
          }
        }
        localStorage.setItem('error',JSON.parse('false'));
        this.loadingservice.closeBlockingLoader();
      },
      (error) => {
        this.loadingservice.closeBlockingLoader();
      }
    );
  }
  setMarketingStatusValuesList() {
    if (this.marketingStatusList.values.length > 0) {
      let counter = 0;
      for (let i = 0; i < this.marketingStatusList.values.length; i++) {
        counter++;
        if (counter === 1) {
          this.marketingStatusValueList = this.marketingStatusList.values;
        }
      }
    } else {
      this.marketingStatusValueList = [];
    }
  }

  setServicesValuesList() {
    if (this.servicesList.values.length > 0) {
      let counter = 0;
      for (let i = 0; i < this.servicesList.values.length; i++) {
        counter++;
        if (counter === 1) {
          this.servicesValuesList = this.servicesList.values;
        }
      }
    } else {
      this.servicesValuesList = [];
    }
  }

  setIndustryValuesList() {
    if (this.industryStatusList.values.length > 0) {
      let counter = 0;
      for (let i = 0; i < this.industryStatusList.values.length; i++) {
        counter++;
        if (counter === 1) {
          this.industryStatusValueList = this.industryStatusList.values;
        }
      }
    } else {
      this.servicesValuesList = [];
    }
  }

  getUserProfile() {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    if (user.companyName) {
      this.userService.getCompanyDetails(user.companyName).subscribe(
        (res: any) => {
          localStorage.setItem("organisationalDetails",JSON.stringify(res.clientModel));
          console.log(res.clientModel);
          if (user.companyName) {
            this.getAmmountOfErrors();
          } else {
            this.loadingservice.closeBlockingLoader();
          }
        },
        (error) => {
          this.loadingservice.closeBlockingLoader();
        }
      );
    }
  }

  getAmmountOfErrors() {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    this.applicationsService
      .getAmmountOfErrorsForCompany(user.companyName)
      .subscribe(
        (res: any) => {
          this.totalComments = res.totalNumberOfComments;
          localStorage.setItem('totalComments',JSON.stringify(this.totalComments));
          this.loadingservice.closeBlockingLoader();
        },
        (error) => {
          this.loadingservice.closeBlockingLoader();
        }
      );
  }

  logout() {
    this.loggedOffUser = JSON.parse(localStorage.getItem('currentUser'));
    this.userFullName = this.loggedOffUser.firstname + ' ' + this.loggedOffUser.lastname;
    this.loadingservice.openBlockingLoader('Logging out ...');

    setTimeout(() => {
      this.router.navigateByUrl('/login');
      this.resetForms();
      this.loadingservice.closeBlockingLoader();
    }, 1000);
     this.clearLocalStorage();
    sessionStorage.clear();
  }

  clearLocalStorage() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('all-applications');
    localStorage.removeItem('newApplicationLookup');
    localStorage.removeItem('randid');
    localStorage.removeItem('industry');
    localStorage.removeItem('error');
    localStorage.removeItem('totalComments');
    localStorage.removeItem('marketing');
    localStorage.removeItem('organisationalDetails');
    localStorage.removeItem('services');
}

  getUserDetails() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser) {
      this.user = true;
      this.userName = this.currentUser.email;
    }
  }
  goToProfileSettings() {
    this.router.navigateByUrl('/users-settings');
  }

  gotoNotifications() {
    this.router.navigateByUrl('/notifications');
  }

  goToContactUs() {
    this.router.navigateByUrl('/contact-us');
  }

  resetForms() {
    this.newApplicationService.generalInfoForm.reset();
    this.newApplicationService.generalInfoForm.updateValueAndValidity();
    this.newApplicationService.verificationElementsForm.reset();
    this.newApplicationService.verificationElementsForm.updateValueAndValidity();
    this.newApplicationService.submissionForm.reset();
    this.newApplicationService.submissionForm.updateValueAndValidity();
    this.newApplicationService.clientDetailsForm.reset();
    this.newApplicationService.clientDetailsForm.updateValueAndValidity();

  }
}
