import { Component, OnInit } from '@angular/core';
import { LayoutService } from '../layout.service';
import { NavService } from './nav-service';
import { animateText, onMainContentChange, onSideNavChange } from './animation';
import { Router } from '@angular/router';

@Component({
  selector: 'tlvt-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  animations: [animateText, onMainContentChange, onSideNavChange]
})
export class SidenavComponent implements OnInit {
  nav: any[] = [];
  public linkText = false;
  nameText = 'null';
  name = 'null';
  surname = '';
  shortHand = '';
  topVal = 0;
  bottomVal = 0;

  public sideNavState = false;

  constructor(public ls: LayoutService, public navService: NavService, private router: Router) {
    this.topVal = (1080 - window.innerHeight) * 0.1619047619047619;
    this.topVal = 98 - this.topVal;
    this.bottomVal = (1080 - window.innerHeight) * 0.2857142857142857;
    this.bottomVal = 244 - this.bottomVal;
    this.ls.sideNavState$.subscribe(res => {
      this.sideNavState = res;
    });
  }

  openSideNav() {
    this.sideNavState = !this.sideNavState;

    if(this.sideNavState) {
      this.ls.sideNavState$.next(true);
    } else {
      this.ls.sideNavState$.next(false);
    }
  }

  returnHeight(marg, val) {
    return {
      [marg]: val + 'px'
    }
  }

  ngOnInit() {
    this.nav = this.navService.getNavigation('navItems');

  }

  onSidenavToggle() {
    this.sideNavState = !this.sideNavState;
    this.ls.sideNavState$.next(this.sideNavState);
  }

  goToLogin() {
    this.router.navigateByUrl('/login')
  }

}
