
export class Navigation {
    public navItems: any[];
    loginRoute = '/login';
    constructor(
    ) {
        this.navItems = [
            { name: 'My Dashboard', link: '/dashboard', icon: 'dashboard' },
            { name: 'Applications', link: '/applications', icon: 'assessment' },
            //  { name: 'Documents', link: '/documents', icon: 'chrome_reader_mode' },
            // { name: 'Log out', link: '/login', icon: 'logout' }
        ];
    }  
}
