import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserWarningComponent } from './user-warning.component';

@Injectable({
  providedIn: 'root'
})
export class UserWarningService {

  constructor(public dialog: MatDialog) { }

  openUserWarning(hasDigitalSignature, companyName) {
    const dialogRef = this.dialog.open(UserWarningComponent, {
      panelClass: 'custom-user-dialog-container',
      data: {
        hasDigitalSignature: false,
        hasCompanyName: companyName
      }
    })
  }
}
