import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LayoutService } from '../layout.service';
import { MatDialog } from '@angular/material/dialog';
import { LoadingService } from '../../shared/components/loading/loading.service';

@Component({
  selector: 'tlvt-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() logo: string;
  @Input() fixed: boolean;
  @Input() buttonIcon: string;
  @Input() color: string;
  @Input() headerHeight: string;
  @Output() buttonClicked = new EventEmitter();
  selectedAdapter: any;
  adaptersLoaded = false;

  newCompany: any;

  constructor(
    public ls: LayoutService,
    public dialog: MatDialog,
    public loadingService: LoadingService ) { }

  ngOnInit() {
  }

  onButtonClicked(e) {
    this.buttonClicked.emit(e);
  }
}
