import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NewApplicationService } from '../../application/new-application/new-application.service';
import { Navigation } from '../../navigation.model';

@Injectable({
  providedIn: 'root'
})
export class NavService {

  /**
   * @property {Navigation} navigation Property for navigation items
   */
  public navigation: Navigation;
  constructor(
    private router: Router,
    public newApplicationService: NewApplicationService

  ) {
    this.navigation = new Navigation();
  }

  /**
   * 
   * @param item Object containing navigation properties
   * @returns {Navigation} Navigation item
   */
  getNavigation(item) {
    if(this.navigation.loginRoute){
      this.resetForms()
      return this.navigation[item];
    } else 
    return this.navigation[item];
  }

    resetForms() {
    this.newApplicationService.generalInfoForm.reset();
    this.newApplicationService.generalInfoForm.updateValueAndValidity();
    this.newApplicationService.verificationElementsForm.reset();
    this.newApplicationService.verificationElementsForm.updateValueAndValidity();
    this.newApplicationService.submissionForm.reset();
    this.newApplicationService.submissionForm.updateValueAndValidity();
    this.newApplicationService.clientDetailsForm.reset();
    this.newApplicationService.clientDetailsForm.updateValueAndValidity();

  }
}
