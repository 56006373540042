import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { Subject } from 'rxjs';

@Component({
  selector: 'sym-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  addressform: UntypedFormGroup;
  passwordMatch = true;
  displaySpinner = false;
  userProfile: UntypedFormGroup;
  errorMessage = '';
  from: any;
  accountFormErrors: any;
  endPoint = environment.API.userManagement;
  imageSrc = '../assets/logos/tlvt-logo.png';
  isCompleted = true;

  @ViewChild('libRegister', { static: false }) libRegister: any;
  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private router: Router) {
  }

  user: any;

  ngOnInit(): void {
    this.isCompleted = true;
    this.accountFormErrors = {
      email: {},
      cellNumber: {}
    };
    this.userProfile = this.fb.group({
      username: ['', [Validators.required]],
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      cellNumber: ['', [Validators.required, Validators.pattern(/^[0-9]{10,10}$/)]],
      status: '',
      profileImage: '',
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
      number: '',
      street: '',
      suburb: '',
      city: '',
      province: '',
      code: ''
    });
    this.userProfile.valueChanges.subscribe(() => {
      this.onAccountFormValuesChanged();
    });
  }

  checkIfPasswordsMatch() {
    if (this.userProfile.value.password !== this.userProfile.value.confirmPassword) {
      this.errorMessage = 'Passwords do not match';
      this.passwordMatch = false;
    } else {
      this.passwordMatch = true;
    }
  }

  onAccountFormValuesChanged() {

    for (const field in this.accountFormErrors) {
      if (!this.accountFormErrors.hasOwnProperty(field)) {
        continue;
      }

      // Clear previous errors
      this.accountFormErrors[field] = {};

      // Get the control
      const control = this.userProfile.get(field);
      if (control && control.dirty && !control.valid) {
        this.accountFormErrors[field] = control.errors;
      }
    }
  }

  getAddress(place) {

    this.addressform = place;
  }

  register() {
    this.isCompleted = false;
    this.checkIfPasswordsMatch();
    if (this.passwordMatch) {
      this.libRegister.onSubmit();
    }
  }

  goBack() {
    this.libRegister.onBack();
  }
}
