<button matTooltip="User options" mat-button [matMenuTriggerFor]="userMenu" class="user-button" matBadge="{{totalComments}}"
  matBadgePosition="before" matBadgeColor="warn" style="margin-top: 10px;" *ngIf="totalComments">
  <div fxLayout="row">
    <img class="avatar" src="assets/images/avatars/profile.jpg">
    <!-- <span class="username mr-12" fxHide fxShow.gt-sm>{{userName}}</span> -->
    <span class="username mr-12" fxHide fxShow.gt-sm>{{userName}}</span>
    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
  </div>
</button>
<button matTooltip="User options" mat-button [matMenuTriggerFor]="userMenu" class="user-button"
  matBadgePosition="before" matBadgeColor="warn" style="margin-top: 10px;" *ngIf="!totalComments">
  <div fxLayout="row">
    <img class="avatar" src="assets/images/avatars/profile.jpg">
    <!-- <span class="username mr-12" fxHide fxShow.gt-sm>{{userName}}</span> -->
    <span class="username mr-12" fxHide fxShow.gt-sm>{{userName}}</span>
    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
  </div>
</button>
<mat-menu #userMenu="matMenu" xPosition="before" style="height:150px;">
  <button matTooltip="User Profile" matTooltipPosition="left" (click)="goToProfileSettings()" mat-menu-item
    class="menu">
    <mat-icon>account_circle</mat-icon>
    <span>Profile</span>
  </button>
  <mat-divider></mat-divider>
  <button matTooltip="Notifications" matTooltipPosition="left" mat-menu-item class="menu" (click)="gotoNotifications()" *ngIf="totalComments">
    <mat-icon matBadge="{{totalComments}}" matBadgeColor="warn" >notification_important</mat-icon>
    <span>Notifications</span>
  </button>
  <mat-divider></mat-divider>
  <button matTooltip="Get Help" matTooltipPosition="left" (click)="goToContactUs()" mat-menu-item class="menu">
    <mat-icon>help</mat-icon>
    <span>Contact Us</span>
  </button>
  <mat-divider></mat-divider>
  <button matTooltip="Signout of your account" matTooltipPosition="left" (click)="logout()" mat-menu-item class="menu">
    <mat-icon>exit_to_app</mat-icon>
    <span>Log out</span>
  </button>
</mat-menu>