<!-- <sym-page-header [title]="'Dashboard'"></sym-page-header> -->
<div class="page-layout blank" fxLayout="column">
  
  <div style="overflow: hidden;height: 100%;padding-bottom: 5em;" fxLayout="column">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center" style="margin-top:70px;">
    <button mat-raised-button
      style="width: 24vw;height: 20vh; margin: 5px;padding: 5px;background-color:  #0D2430;color: white;" id="btnClass"
      (click)="newApplication()">
      <mat-icon style="color: white;margin-right: 3px;">fiber_new</mat-icon>
      <div fxLayoutAlign="center center" style="font-size:x-large">
        Request New Application
      </div>
    </button>
    <button mat-raised-button
      style="width: 24vw;height: 20vh; margin: 5px;padding: 5px;background-color:  #85c3cf;color: white;" id="btnClass"
      (click)="myApplications()">
      <mat-icon style="color: white;margin-right: 3px;">assessment</mat-icon>
      <div fxLayoutAlign="center center" style="font-size:x-large">
        View My Applications
      </div>
    </button>
  </div>
  <div fxLayout="column" fxLayoutAlign="center center" fxFlex="50" style="margin-top: 3em">
    <div style="color:black">
      Our B-BBEE Consulting Services
    </div>
    <br>
    <div style="color:black">B-BBEE Verifications – TLVT offers verification services in accordance with the Generic
      Codes and all gazetted
      Sector Codes.</div>
    <br>
    <div style="color:black">
      We Offer:
    </div>
    <div fxFlexAlign="center center" style="color:black"> B-BBEE Verifications,</div>
    <div style="color:black"> B-BBEE Assessments and Indicative Scoring,<br> </div>
    <div style="color:black"> Sector Code Conversion,<br></div>
    <div style="color:black"> Ownership Structure Analysis <br></div>
    <br>
    <div style="color:black"> TLVT is a 100% black-owned, Level 1 B-BBEE verification agency</div>

  </div>
</div>
</div>
