import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { AuthGuard } from './core/auth/auth.guard';
import { DashboardComponent } from './features/dashboard/dashboard.component';

export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        path: 'applications',
        loadChildren: () =>
          import('./application/application.module').then(
            (mod) => mod.ApplicationModule
          ),
      },
      {
        path: 'new-application',
        loadChildren: () =>
          import('./application/new-application/new-application.module').then(
            (mod) => mod.NewApplicationModule
          ),
          data: {
            breadcrumbStart: '/dashboard'
          }
      },
      {
        path: 'view-application',
        loadChildren: () =>
          import('./application/view-application/view-application.module').then(
            (mod) => mod.ViewApplicationModule
          ),
          data: {
            breadcrumbStart: '/applications'
          }
      },
      {
        path: 'renew-application/:id',
        loadChildren: () =>
          import('./application/renew-application/renew-application.module').then(
            (mod) => mod.RenewApplicationModule
          ),
      },
      {
        path: 'documents',
        loadChildren: () =>
          import('./documents/documents.module').then(
            (mod) => mod.DocumentsModule
          ),
      },
      {
        path: 'contact-us',
        loadChildren: () =>
          import('./help/help.module').then(
            (mod) => mod.HelpModule
          ),
          data: {
            breadcrumbStart: '/dashboard'
          }
      },
      {
        path: 'users-settings',
        loadChildren: () =>
          import('./features/users-settings/users-settings.module').then(
            (mod) => mod.UsersSettingsModule
          ),
          data: {
            breadcrumbStart: '/dashboard'
          }
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('./notifications/notifications.module').then(
            (mod) => mod.NotificationsModule
          ),
      },
    ],
  }
  ,
  {
    path: 'login',
    loadChildren: () => import('./core/auth/auth.module').then(m => m.AuthModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
