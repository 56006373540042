<mat-card class="white-bg mat-elevation-z8 m-0 p-0" (mouseleave)="removeHover();">

    <mat-card-title-group class="p-4 " [ngStyle]="">
        <mat-card-title style="flex-wrap: wrap;">
            <div style="font-size: large !important;margin-top: 5px;margin-left: 5px;">
                {{title}}
            </div>

        </mat-card-title>
        <div>
            <ng-content select="[id=titleExtras]"></ng-content>
            <sym-card-button class="mr-4" [icon]="icon" (buttonClicked)="onButtonClicked($event)" *ngIf="showButton">
            </sym-card-button>
        </div>
    </mat-card-title-group>

    <mat-card-content class="p-6">

        <ng-template #content>
            <ng-content #body></ng-content>
        </ng-template>

        <ng-container *ngTemplateOutlet="content"></ng-container>
    </mat-card-content>
</mat-card>