import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, MaxLengthValidator, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { ComsService } from '../../shared/components/coms/coms.service';

; @Injectable({
  providedIn: 'root'
})
export class NewApplicationService {
  showSetSameAddress = false;
  consultantName: any;
  submissionForm: UntypedFormGroup;
  clientDetailsForm: UntypedFormGroup;
  generalInfoForm: UntypedFormGroup;
  verificationElementsForm: UntypedFormGroup;
  initialFilesForm: UntypedFormGroup;
  ccGroup: UntypedFormGroup;
  sameAsPhysicalAddress = false;
  isConflict = false;
  isConsultant = false;
  consultantVal: string;
  conflictVal: string;
  organisationalDetails: any;
  useSignature = false;
  showConsultantFile = false;
  agencyName: any;
  applicationObj: any;
  appApi = environment.API.applicationManagement;
  documentsApi = environment.API.documentManagement;

  constructor(
    public fb: UntypedFormBuilder,
    public coms: ComsService,
    private http: HttpClient
  ) {
    this.setForms();
  }



  setForms() {
    this.initGeneralInformationForm();
    this.initClientDetailsForm();
    this.initElementsForm();
    this.initFilesForm();
    this.initSubmissionForm();
  }
  initGeneralInformationForm() {
    const date = new Date();
    this.generalInfoForm = this.fb.group({
      serviceRequired: ['', Validators.required],
      serviceDescription: [''],
      marketing: ['', Validators.required],
      madeUseOfConsultant: [''],
      companyConsultant: [''],
      hasConflict: [''],
      conflictOfInterest: [''],
      groupStructure: [''],
      currentComissionInvestigation: [''],
      currentComissionInvestigationReason: [''],
      previousComissionInvestigation: [''],
      previousComissionInvestigationReason: [''],
      scorecardConsolidation: [''],
      cetralisedBusiness: [''],
      certExpDate: [''],
      provideOganogram: [''],
      externalConnection: [''],
      externalReason: [''],
      previousConsulting: [''],
      previousConsultingReason: [''],
      powerOfAttorneyRequired: ['']
    });
  }
  initClientDetailsForm() {
    this.clientDetailsForm = this.fb.group({
      chosenPrincipalContact: ['', Validators.required],
      consultant: this.fb.group({
        agencyName: ['', Validators.required],
        name: ['', Validators.required],
        position: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        telNumber: ['', [Validators.required, Validators.minLength(12)]],
        cellNumber: ['', [Validators.required, Validators.minLength(12)]],
        alternateContact: this.fb.array([])
      }),
      companyRep: this.fb.group({
        name: ['', Validators.required],
        position: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        telNumber: ['', [Validators.required, Validators.minLength(12)]],
        cellNumber: ['', [Validators.required, Validators.minLength(12)]],
        alternateContact: this.fb.array([])
      }),
      projectManager: this.fb.group({
        name: ['', Validators.required],
        position: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        telNumber: ['', [Validators.required, Validators.minLength(12)]],
        cellNumber: ['', [Validators.required, Validators.minLength(12)]],
        alternateContact: this.fb.array([])
      }),
      organisationName: ['', Validators.required],
      organisationRegisterNumber: ['', [Validators.required]],
      totalNumberOfEmployees: ['', Validators.required],
      tradingName: ['', Validators.required],
      vatNumber: ['', [Validators.required, Validators.pattern(/^[0-9]{10}$/)]],
      totalNumberOfOffices: ['', Validators.required],
      physicalAddress: this.fb.group({
        addressLine1: ['', Validators.required],
        addressSuburb: ['', Validators.required],
        addressCity: ['', Validators.required],
        addressPostCode: ['', [Validators.required, Validators.pattern(/^[0-9]{4}$/)]],
        addressProvince: ['', Validators.required]
      }),
      postalAddress: this.fb.group({
        postalLine1: ['', Validators.required],
        postalCity: ['', Validators.required],
        postalCode: ['', [Validators.required, Validators.pattern(/^[0-9]{4}$/)]],
        sameAsPhysicalAddress: this.fb.array([])
      }),
      additionalOffices: this.fb.group({
        otherRegisteredOffices: this.fb.array([])
      }),
      finance: this.fb.group({
        mainIncomeSource: ['', Validators.required],
        estAnnualTurnover: ['', Validators.required],
        accountManangeType: ['', Validators.required],
        industrialSector: ['', Validators.required],
        financialReportingPeriod: ['', Validators.required],
        financialReviewPeriodStart: ['', Validators.required],
        financialReviewPeriodEnd: ['', Validators.required],
      }),
    });
  }

  initElementsForm() {
    this.verificationElementsForm = this.fb.group({
      ownership: this.fb.group({
        naturalPersons: [''],
        multiNational: [''],
        multiLevel: [''],
        trusts: [''],
        modifiedFlow: [''],
        listedEntity: [''],
        noOfBlackShareholders: ['', Validators.required],
        blackShareholderOwnership: ['', Validators.required]
      }),
      managementControl: this.fb.group({
        noOfBoardMemembers: ['', Validators.required],
        noOfExecutiveTopManagement: ['', Validators.required],
        noOfMiddleManagement: ['', Validators.required],
        noOfDisabledEmployees: ['', Validators.required],
        noOfExecutiveDirectors: ['', Validators.required],
        noOfSeniorManagement: ['', Validators.required],
        noOfJuniorManagement: ['', Validators.required],
        yesProgram: ['']
      }),
      skillsDevelopment: this.fb.group({
        noBlackOnTraining: ['', Validators.required]
      }),
      enterpriseDevelopment: this.fb.group({
        totalSuppliers: ['', Validators.required],
        totalImportSuppliers: ['', Validators.required],
        noOfEDEntities: ['', Validators.required],
        approxProcurementSpend: ['', Validators.required],
        approxImportProcurementSpend: ['', Validators.required],
        noOfSDEntities: ['', Validators.required],
        collectBEECertificates: ['']
      }),
      socioEd: this.fb.group({
        noOfSDEntities: ['', Validators.required],
      }),
      other: this.fb.group({
        description: ['', Validators.required],
      })
    });
  }
  initFilesForm() {
    this.initialFilesForm = this.fb.group({
      files: ['']
    });
  }
  initSubmissionForm() {
    this.submissionForm = this.fb.group({
      deliveryDate: ['', Validators.required],
      name: ['', Validators.required],
      date: ['', Validators.required],
      capacity: ['', Validators.required],
      useDigitalSignature: [''],
      releaseCertificate: [''],
      employeeList: this.fb.array([])
    });
  }
  showConflictSelection(e) {
  }
  showConsultantSelection(e) {
  }
  copyPhysicalAddress(e,display) {
    if (e.checked) {
      (this.clientDetailsForm.get('postalAddress') as UntypedFormGroup).disable();
      this.setSameAsPhysicalAddress();
      if(display) {
        this.coms.showMsg('Your postal address is the same as your physical address', 'info');
      }
    } else {
      (this.clientDetailsForm.get('postalAddress') as UntypedFormGroup).enable();
      this.coms.showMsg('Please enter a PO Box address', 'error');
    }
  }


  checkConsultantName(e) {
    this.consultantName = this.generalInfoForm.value.companyConsultant;
    (this.clientDetailsForm.get('consultant') as UntypedFormGroup).setControl('name', new UntypedFormControl(this.consultantName));
    if (this.consultantName) {
      return this.showConsultantFile = true;
    } else {
      return this.showConsultantFile = false;
    }
  }
  setContactFormValidators() {
    switch (this.clientDetailsForm.controls.chosenPrincipalContact.value) {
      case 'consultant':
        this.clearAllContactValidators('consultant');
        this.setContactValidators('consultant');
        break;
      case 'companyRep':
        this.clearAllContactValidators('companyRep');
        this.setContactValidators('companyRep');
        break;
      case 'projectManager':
        this.clearAllContactValidators('projectManager');
        this.setContactValidators('projectManager');
        break;

      default:
        break;
    }
  }
  setContactValidators(type) {
    this.clientDetailsForm.get(type).get('name').setValidators([Validators.required]);
    this.clientDetailsForm.get(type).get('email').setValidators([Validators.required, Validators.email]);
    this.clientDetailsForm.get(type).get('position').setValidators([Validators.required]);
    this.clientDetailsForm.get(type).get('name').updateValueAndValidity();
    this.clientDetailsForm.get(type).get('email').updateValueAndValidity();
    this.clientDetailsForm.get(type).get('position').updateValueAndValidity();
    if (this.clientDetailsForm.get(type).get('cellNumber').value) {
      this.clientDetailsForm.get(type).get('cellNumber').setValidators([Validators.required, Validators.maxLength(12)]);
      this.clientDetailsForm.get(type).get('telNumber').setValidators([Validators.maxLength(12)]);
      this.clientDetailsForm.get(type).get('telNumber').updateValueAndValidity();
    }
    if (this.clientDetailsForm.get(type).get('telNumber').value) {
      this.clientDetailsForm.get(type).get('telNumber').setValidators([Validators.required, Validators.maxLength(12)]);
      this.clientDetailsForm.get(type).get('cellNumber').setValidators([Validators.maxLength(12)]);
      this.clientDetailsForm.get(type).get('cellNumber').updateValueAndValidity();
    }
  }
  clearAllContactValidators(type) {
    const contactTypes = [
      'projectManager', 'consultant'
    ];
    contactTypes.forEach(cType => {
      if (cType !== type) {
        this.clientDetailsForm.get(cType).get('name').setValidators([]);
        this.clientDetailsForm.get(cType).get('email').setValidators([]);
        this.clientDetailsForm.get(cType).get('position').setValidators([]);
        this.clientDetailsForm.get(cType).get('telNumber').setValidators([]);
        this.clientDetailsForm.get(cType).get('cellNumber').setValidators([]);
        this.clientDetailsForm.get('consultant').get('agencyName').setValidators([]);
        this.clientDetailsForm.get(cType).get('name').updateValueAndValidity();
        this.clientDetailsForm.get(cType).get('email').updateValueAndValidity();
        this.clientDetailsForm.get(cType).get('position').updateValueAndValidity();
        this.clientDetailsForm.get(cType).get('telNumber').updateValueAndValidity();
        this.clientDetailsForm.get(cType).get('cellNumber').updateValueAndValidity();
        this.clientDetailsForm.get('consultant').get('agencyName').updateValueAndValidity();
      }
    });
  }
  initConsultantAlt(): UntypedFormGroup {
    this.agencyName = (this.clientDetailsForm.get('consultant') as UntypedFormGroup).controls.agencyName.value;
    return this.fb.group({
      agencyName: [this.agencyName],
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      position: ['', [Validators.required]],
      telNumber: ['', [Validators.minLength(10)]],
      cellNumber: ['', [Validators.required, Validators.minLength(10)]]
    });
  }
  removeAltContact(i, type) {
    if (type === 'companyRep') {
      const control = this.clientDetailsForm.controls.companyRep.get('alternateContact') as UntypedFormArray;
      control.removeAt(i);
    }
    if (type === 'consultant') {
      const control = this.clientDetailsForm.controls.consultant.get('alternateContact') as UntypedFormArray;
      control.removeAt(i);
    }
    if (type === 'projectManager') {
      const control = this.clientDetailsForm.controls.projectManager.get('alternateContact') as UntypedFormArray;
      control.removeAt(i);
    }
  }
  addBlankAlt(type) {
    if (type === 'companyRep') {
      const control = this.clientDetailsForm.controls.companyRep.get('alternateContact') as UntypedFormArray;
      control.push(this.initAlt());
    }
    if (type === 'consultant') {
      const control = this.clientDetailsForm.controls.consultant.get('alternateContact') as UntypedFormArray;
      control.push(this.initConsultantAlt());
    }
    if (type === 'projectManager') {
      const control = this.clientDetailsForm.controls.projectManager.get('alternateContact') as UntypedFormArray;
      control.push(this.initAlt());
    }
  }
  initAlt(): UntypedFormGroup {
    return this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.email]],
      position: ['', [Validators.required]],
      telNumber: ['', [Validators.minLength(10)]],
      cellNumber: ['', [Validators.required, Validators.minLength(10)]],
    });
  }
  initSameAsPhysicalAddress(): UntypedFormGroup {
    return this.fb.group({
      postalAddressLine1: [this.clientDetailsForm.value.physicalAddress.addressLine1],
      postalAddressSuburb: [this.clientDetailsForm.value.physicalAddress.addressSuburb],
      postalAddressCity: [this.clientDetailsForm.value.physicalAddress.addressCity],
      postalAddressPostCode: [this.clientDetailsForm.value.physicalAddress.addressPostCode],
      postalAddressProvince: [this.clientDetailsForm.value.physicalAddress.addressProvince]
    });
  }
  setSameAsPhysicalAddress() {
    this.showSetSameAddress = true;
    const control = this.clientDetailsForm.controls.postalAddress.get('sameAsPhysicalAddress') as UntypedFormArray;
    control.push(this.initSameAsPhysicalAddress());
    this.clientDetailsForm.controls.postalAddress.get('postalCity').setValue(control.value[0].postalAddressCity)
    this.clientDetailsForm.controls.postalAddress.get('postalLine1').setValue(control.value[0].postalAddressLine1)
    this.clientDetailsForm.controls.postalAddress.get('postalCode').setValue(control.value[0].postalAddressPostCode)
  }
  createApplication() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const userId = currentUser.userId
    this.consultantVal = this.generalInfoForm.value.companyConsultant;
    this.consultantVal = this.generalInfoForm.value.conflictOfInterest;
    if (this.consultantVal != '') {
      this.generalInfoForm.value.madeUseOfConsultant = true;
    }

    var madeUseOfConsultant1;
    var hasConflict1;
    var currentComissionInvestigation1;
    var previousComissionInvestigation1;
    var groupStructure1;
    var scorecardConsolidation1;
    var cetralisedBusiness1;
    var provideOganogram1;
    var listedEntity1;
    var naturalPersons1;
    var multiNational1;
    var multiLevel1;
    var trusts1;
    var modifiedFlow1;
    var yesProgram1;
    var sameAsPhysicalAddress1;
    var collectBEECertificates1;
    var useDigitalSignature1;
    var releaseCertificate1;
    var externalConnection1;
    var previousConsulting1;
    var powerOfAttorneyRequired1;

    if (!this.generalInfoForm.value.externalConnection || this.generalInfoForm.value.externalConnection == null || this.generalInfoForm.value.externalConnection == '') {
      externalConnection1 = false;
    } else {
      externalConnection1 = true;
    }

    if (!this.generalInfoForm.value.previousConsulting || this.generalInfoForm.value.previousConsulting == null || this.generalInfoForm.value.previousConsulting == '') {
      previousConsulting1 = false;
    } else {
      previousConsulting1 = true;
    }

    if (!this.generalInfoForm.value.powerOfAttorneyRequired || this.generalInfoForm.value.powerOfAttorneyRequired == null || this.generalInfoForm.value.powerOfAttorneyRequired == '') {
      powerOfAttorneyRequired1 = false;
    } else {
      powerOfAttorneyRequired1 = true;
    }

    if (!this.generalInfoForm.value.companyConsultant || this.generalInfoForm.value.companyConsultant == null || this.generalInfoForm.value.companyConsultant == '') {
      madeUseOfConsultant1 = false;
    } else {
      madeUseOfConsultant1 = true;
    }

    if (!this.generalInfoForm.value.hasConflict || this.generalInfoForm.value.hasConflict == null || this.generalInfoForm.value.hasConflict == '') {
      hasConflict1 = false;
    } else {
      hasConflict1 = true;
    }

    if (!this.generalInfoForm.value.currentComissionInvestigation || this.generalInfoForm.value.currentComissionInvestigation == null) {
      currentComissionInvestigation1 = false;
    } else {
      currentComissionInvestigation1 = true;
    }

    if (!this.generalInfoForm.value.previousComissionInvestigation || this.generalInfoForm.value.previousComissionInvestigation == null) {
      previousComissionInvestigation1 = false;
    } else {
      previousComissionInvestigation1 = true;
    }

    if (!this.generalInfoForm.value.groupStructure || this.generalInfoForm.value.groupStructure == null) {
      groupStructure1 = false;
    } else {
      groupStructure1 = true;
    }

    if (!this.generalInfoForm.value.scorecardConsolidation || this.generalInfoForm.value.scorecardConsolidation == null) {
      scorecardConsolidation1 = false;
    } else {
      scorecardConsolidation1 = true;
    }

    if (!this.generalInfoForm.value.cetralisedBusiness || this.generalInfoForm.value.cetralisedBusiness == null) {
      cetralisedBusiness1 = false;
    } else {
      cetralisedBusiness1 = true;
    }

    if (!this.generalInfoForm.value.provideOganogram || this.generalInfoForm.value.provideOganogram == null) {
      provideOganogram1 = false;
    } else {
      provideOganogram1 = true;
    }

    if (!this.verificationElementsForm.value.ownership.listedEntity || this.verificationElementsForm.value.ownership.listedEntity == null) {
      listedEntity1 = false;
    } else {
      listedEntity1 = true;
    }

    if (!this.verificationElementsForm.value.ownership.naturalPersons || this.verificationElementsForm.value.ownership.naturalPersons == null) {
      naturalPersons1 = false;
    } else {
      naturalPersons1 = true;
    }

    if (!this.verificationElementsForm.value.ownership.multiNational || this.verificationElementsForm.value.ownership.multiNational == null) {
      multiNational1 = false;
    } else {
      multiNational1 = true;
    }

    if (!this.verificationElementsForm.value.ownership.multiLevel || this.verificationElementsForm.value.ownership.multiLevel == null) {
      multiLevel1 = false;
    } else {
      multiLevel1 = true;
    }

    if (!this.verificationElementsForm.value.ownership.trusts || this.verificationElementsForm.value.ownership.trust == null) {
      trusts1 = false;
    } else {
      trusts1 = true;
    }

    if (!this.verificationElementsForm.value.ownership.modifiedFlow || this.verificationElementsForm.value.ownership.modifiedFlow == null) {
      modifiedFlow1 = false;
    } else {
      modifiedFlow1 = true;
    }

    if (!this.verificationElementsForm.value.managementControl.yesProgram || this.verificationElementsForm.value.managementControl.yesProgram == null) {
      yesProgram1 = false;
    } else {
      yesProgram1 = true;
    }

    if (this.clientDetailsForm.value.postalAddress.sameAsPhysicalAddress.length === 0) {
      sameAsPhysicalAddress1 = false;
    } else {
      sameAsPhysicalAddress1 = true;
    }

    if (!this.verificationElementsForm.value.enterpriseDevelopment.collectBEECertificates || this.verificationElementsForm.value.enterpriseDevelopment.collectBEECertificates == null) {
      collectBEECertificates1 = false;
    } else {
      collectBEECertificates1 = true
    }

    if (!this.submissionForm.value.useDigitalSignature || this.submissionForm.value.useDigitalSignature == null) {
      useDigitalSignature1 = false;
    } else {
      useDigitalSignature1 = true;
    }

    if (!this.submissionForm.value.releaseCertificate || this.submissionForm.value.releaseCertificate == null) {
      releaseCertificate1 = false;
    } else {
      releaseCertificate1 = true;

    }
    const reviewPeriodStart = new Date(this.clientDetailsForm.value.finance.financialReviewPeriodStart);
    const reviewPeriodEnd = new Date(this.clientDetailsForm.value.finance.financialReviewPeriodEnd);
    const app = {
      application: {
        dateReceived: new Date(),
        measuredEntity: {
          organisationName: this.clientDetailsForm.value.organisationName || '',
          organisationRegisterNumber: this.clientDetailsForm.value.organisationRegisterNumber || '',
          totalNumberOfEmployees: this.clientDetailsForm.value.totalNumberOfEmployees.toString() || '',
          tradingName: this.clientDetailsForm.value.tradingName || '',
          vatNumber: this.clientDetailsForm.value.vatNumber || '',
          totalNumberOfOffices: this.clientDetailsForm.value.totalNumberOfOffices.toString() || '',
          financialInformation: {
            mainIncomeSource: this.clientDetailsForm.value.finance.mainIncomeSource || '',
            estAnnualTurnover: this.clientDetailsForm.value.finance.estAnnualTurnover.toString() || '',
            accountManangeType: this.clientDetailsForm.value.finance.accountManangeType || '',
            industrialSector: this.clientDetailsForm.value.finance.industrialSector || '',
            financialReportingPeriod: this.clientDetailsForm.value.finance.financialReportingPeriod || '',
            financialReviewPeriod: reviewPeriodStart.toISOString().slice(0, 10) + ' to ' + reviewPeriodEnd.toISOString().slice(0, 10)
            // financialReviewPeriod: this.clientDetailsForm.value.finance.financialReportingPeriod || ''
          }
        },
        generalInfo: {
          serviceRequired: this.generalInfoForm.value.serviceRequired || '',
          serviceDescription: this.generalInfoForm.value.serviceDescription || '',
          marketing: this.generalInfoForm.value.marketing || '',
          madeUseOfConsultant: madeUseOfConsultant1,
          companyConsultant: this.generalInfoForm.value.companyConsultant || '',
          hasConflict: hasConflict1,
          conflictOfInterest: this.generalInfoForm.value.conflictOfInterest || '',
          groupStructure: groupStructure1,
          currentComissionInvestigation: currentComissionInvestigation1,
          currentComissionInvestigationReason: this.generalInfoForm.value.currentComissionInvestigationReason || '',
          previousComissionInvestigation: previousComissionInvestigation1,
          previousComissionInvestigationReason: this.generalInfoForm.value.previousComissionInvestigationReason || '',
          scorecardConsolidation: scorecardConsolidation1,
          cetralisedBusiness: cetralisedBusiness1,
          certExpDate: this.generalInfoForm.value.certExpDate || '',
          provideOganogram: provideOganogram1,
          previousConsulting: previousConsulting1,
          powerOfAttorneyRequired: powerOfAttorneyRequired1,
          previousConsultingReason: this.generalInfoForm.value.previousConsultingReason || '',
          externalConnection: externalConnection1,
          externalReason: this.generalInfoForm.value.externalReason || '',
        },
        officeInfo: {
          physicalAddress: {
            addressLine1: this.clientDetailsForm.value.physicalAddress.addressLine1 || '',
            addressSuburb: this.clientDetailsForm.value.physicalAddress.addressSuburb || '',
            addressCity: this.clientDetailsForm.value.physicalAddress.addressCity || '',
            addressPostCode: this.clientDetailsForm.value.physicalAddress.addressPostCode || '',
            addressProvince: this.clientDetailsForm.value.physicalAddress.addressProvince || '',
          },
          postalAddress: {
            postalLine1: this.clientDetailsForm.value.postalAddress.postalLine1 || '',
            postalCity: this.clientDetailsForm.value.postalAddress.postalCity || '',
            postalCode: this.clientDetailsForm.value.postalAddress.postalCode || '',
            sameAsPhyiscalAddress: sameAsPhysicalAddress1,
          },
          additionalOffices: {
            otherRegisteredOffices: this.clientDetailsForm.value.additionalOffices.otherRegisteredOffices || '',
          }
        },
        contactInfo: {
          companyRepDetails: {
            userId: userId,
            name: this.clientDetailsForm.value.companyRep.name || '',
            position: this.clientDetailsForm.value.companyRep.position || '',
            email: this.clientDetailsForm.value.companyRep.email || '',
            telNumber: this.clientDetailsForm.value.companyRep.telNumber || '',
            cellNumber: this.clientDetailsForm.value.companyRep.cellNumber || '',
            alternateContact: this.clientDetailsForm.value.companyRep.alternateContact || ''
          },
          consultantDetails: {
            userId: userId,
            agencyName: this.clientDetailsForm.value.consultant.agencyName || '',
            name: this.clientDetailsForm.value.consultant.name || '',
            position: this.clientDetailsForm.value.consultant.position || '',
            email: this.clientDetailsForm.value.consultant.email || '',
            telNumber: this.clientDetailsForm.value.consultant.telNumber || '',
            cellNumber: this.clientDetailsForm.value.consultant.cellNumber || '',
            alternateContact: this.clientDetailsForm.value.consultant.alternateContact || ''
          },
          projectManagerDetails: {
            userId: userId,
            name: this.clientDetailsForm.value.projectManager.name || '',
            position: this.clientDetailsForm.value.projectManager.position || '',
            email: this.clientDetailsForm.value.projectManager.email || '',
            telNumber: this.clientDetailsForm.value.projectManager.telNumber || '',
            cellNumber: this.clientDetailsForm.value.projectManager.cellNumber || '',
            alternateContact: this.clientDetailsForm.value.projectManager.alternateContact || ''
          }
        },
        elementsForVerification:
        {
          ownership: {
            naturalPersons: naturalPersons1,
            multiNational: multiNational1,
            multiLevel: multiLevel1,
            trusts: trusts1,
            modifiedFlow: modifiedFlow1,
            noOfBlackShareholders: this.verificationElementsForm.value.ownership.noOfBlackShareholders.toString() || '',
            blackShareholderOwnership: this.verificationElementsForm.value.ownership.blackShareholderOwnership.toString() || '',
            listedEntity: listedEntity1
          },
          managementControl: {
            noOfBoardMemembers: this.verificationElementsForm.value.managementControl.noOfBoardMemembers.toString() || '',
            noOfExecutiveTopManagement: this.verificationElementsForm.value.managementControl.noOfExecutiveTopManagement.toString() || '',
            noOfMiddleManagement: this.verificationElementsForm.value.managementControl.noOfMiddleManagement.toString() || '',
            noOfDisabledEmployees: this.verificationElementsForm.value.managementControl.noOfDisabledEmployees.toString() || '',
            noOfExecutiveDirectors: this.verificationElementsForm.value.managementControl.noOfExecutiveDirectors.toString() || '',
            noOfSeniorManagement: this.verificationElementsForm.value.managementControl.noOfSeniorManagement.toString() || '',
            noOfJuniorManagement: this.verificationElementsForm.value.managementControl.noOfJuniorManagement.toString() || '',
            yesProgram: yesProgram1
          },
          skillsDevelopment: {
            noBlackOnTraining: this.verificationElementsForm.value.skillsDevelopment.noBlackOnTraining || ''
          },
          other: {
            description: this.verificationElementsForm.value.other.description || ''
          },
          enterpriseDevelopment: {
            totalSuppliers: this.verificationElementsForm.value.enterpriseDevelopment.totalSuppliers.toString() || '',
            totalImportSuppliers: this.verificationElementsForm.value.enterpriseDevelopment.totalImportSuppliers.toString() || '',
            noOfEDEntities: this.verificationElementsForm.value.enterpriseDevelopment.noOfEDEntities.toString() || '',
            approxProcurementSpend: this.verificationElementsForm.value.enterpriseDevelopment.approxProcurementSpend.toString() || '',
            approxImportProcurementSpend: this.verificationElementsForm.value.enterpriseDevelopment.approxImportProcurementSpend.toString() || '',
            noOfSDEntities: this.verificationElementsForm.value.enterpriseDevelopment.noOfSDEntities.toString() || '',
            collectBEECertificates: collectBEECertificates1
          },
          socioEcoDevelopment: {
            noOfSDEntities: this.verificationElementsForm.value.socioEd.noOfSDEntities.toString() || ''
          }
        }
        ,
        submissionInfo: {
          deliveryDate: this.submissionForm.value.deliveryDate || '',
          name: JSON.parse(localStorage.getItem('fullName')) || '',
          date: this.submissionForm.value.date || '',
          capacity: this.submissionForm.value.capacity || '',
          useDigitalSignature: useDigitalSignature1,
          releaseCertificate: releaseCertificate1,
          employeeCc: {
            listedEmployees: this.submissionForm.value.employeeList || ''
          }
        },
        documentsUploaded: {
          allRequriedDocuments: false,
          powerOfAttorney: JSON.parse(localStorage.getItem("consultantUploaded")) || false,
          groupOrganogram: JSON.parse(localStorage.getItem("organogramUploaded")) || false
        }
      }
    };
    this.clearDrafts();
    return this.http.post(this.appApi + '/applications/all', app);
  }

  clearDrafts() {
    localStorage.removeItem('draft-general-info');
    localStorage.removeItem('draft-client-info')
    localStorage.removeItem('draft-elements-info');
    localStorage.removeItem('draft-submission-info');
  }

  uploadDocument(object) {
    return this.http.post(this.documentsApi + '/all', object);
  }

  uploadDocumentToS3(url, file) {
    return this.http.put(url, file);
  }

  clearApplication() {
    this.generalInfoForm.reset();
    this.clientDetailsForm.reset();
    this.verificationElementsForm.reset();
    this.initialFilesForm.reset();
    this.submissionForm.reset();
  }
}


