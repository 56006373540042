import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Applications } from '../interfaces/applications';
import { ApplicationNotifications } from '../interfaces/applications';
import { Observable, of } from 'rxjs';
import * as moment from 'moment';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { LoadingService } from '../shared/components/loading/loading.service';
import { ComsService } from '../shared/components/coms/coms.service';
@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  appApi = environment.API.applicationManagement;
  commentApi = environment.API.commentManagement;
  cor04Api = environment.API.cor04;
  @Output() getNotifications = new EventEmitter<boolean>();

  selectedApplication: any;
  isNewApplication = false;
  constructor(
    private http: HttpClient,
    private router: Router,
    public loadingService: LoadingService,
    public coms: ComsService
  ) {
  }

  renewApplication(appID) {
    this.router.navigateByUrl('renew-application/' + appID);
    this.loadingService.openBlockingLoader('Fetching Application')
    this.getSelectedApplication(appID).subscribe((res: any) => {
      this.isNewApplication = true;
      this.selectedApplication = res.application;
      localStorage.setItem('selected-app',JSON.stringify(this.selectedApplication));
      this.loadingService.closeBlockingLoader()
    }), error => {
      this.loadingService.closeBlockingLoader();
      this.coms.showMsg('There was an error fetching the selected application', 'error');
    }
  }

  viewApplicationClick(appID) {
    this.router.navigateByUrl('view-application/' + appID);
    this.loadingService.openBlockingLoader('Loading Application Summary')
    this.getSelectedApplication(appID).subscribe((res: any) => {
      this.selectedApplication = res.application;
      this.loadingService.closeBlockingLoader()
    }), error => {
      this.loadingService.closeBlockingLoader();
      this.coms.showMsg('There was an error loading the selected application', 'error');
    }
  }
  
  getAllApplications(companyName) {
    return this.http.get(this.appApi + '/applications/forcompany/' + companyName);
  }

  submitApplication(id) {
    return this.http.post(this.appApi + '/applications/submitapplication', id);
  }
  submitUpdatedApplication(id) {
    return this.http.put(this.appApi + '/applications/submitapplication', id);
  }

  getCurrentUserApplications(id) {
    return this.http.get(this.appApi + '/applications/assignto/' + id);
  }

  getSelectedApplication(id) {
    return this.http.get(this.appApi + '/applications/application/' + id);
  }

  updateApplication(id, application) {
    return this.http.put(this.appApi + '/applications/application/' + id,application);
  }

  getApplicationsForComments(companyName) {
    return this.http.get(this.appApi + '/applications/forcompanywitherrors/' + companyName)
  }
  getCommentsByApplicationId(id) {
    return this.http.get(this.commentApi + '/commentByApplicationId/' + id);
  }
  getAmmountOfErrorsForCompany(companyName) {
    return this.http.get(this.commentApi + '/commentByCompany/' + companyName);
  }
  
  getCor04(id) {
    return this.http.get(this.cor04Api + id);
  }

  submitCor04(cor04,id) {
    return this.http.put(this.cor04Api + id, cor04);
  }

  pingNotifications(data: any) {
    this.getNotifications.emit(data);
 }
}

