import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserProfileComponent } from './user-profile.component';
import { BaseModule } from '../../../core/base/base.module';
import { MaterialModule } from '../../../core/material/material.module';
import { ComsModule } from '../coms/coms.module';
import { LoadingModule } from '../loading/loading.module';
@NgModule({
  declarations: [UserProfileComponent],
  imports: [
    CommonModule,
    BaseModule,
    MaterialModule,
    ComsModule,
    LoadingModule
  ],
  exports: [
    UserProfileComponent
  ]
})
export class UserProfileModule { }
